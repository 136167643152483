@import "./Reset.scss";
@import "./Typography.scss";
@import "./Vars.scss";

html {
  background-color: $black;
}

.first-row {
  display: flex;
  flex-direction: column;
}

.first-row h3,
a {
  margin-bottom: 1.25rem;
}
$sm: 576px;
$md: 767px;
$md-end: 768px;

@media screen and (max-height: 640px) {
  .first-row {
    display: none;
  }
}

/*
* STYLED CHECKBOXES COMPONENT
*/

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: "";
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    background: $black;
    border: 1px solid white;
  }

  // Box checked
  &:checked + label:before {
    background: $black;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 5px;
    background: $white;
    width: 8px;
    height: 8px;
  }
}

.container {
  margin: $spacer2;
  font-size: 10px;

  #nominateBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    font-size: 1.2rem !important;
    height: 5rem;
    border-radius: 0;
    border: 2px solid $white;
    background-color: $black;
    margin-top: $spacer2;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }

    @media only screen and (max-width: $md) {
      height: $spacer3;
      margin: $spacer1 0;
    }
  }

  .mainSite {
    position: fixed;
    bottom: $spacer2;
    left: $spacer2;
    margin: 0 !important;
    z-index: 999;

    > h3 {
      cursor: pointer;
      margin: 0;

      &:hover {
        opacity: 0.5;
      }
    }

    a {
      text-decoration: none;

      span {
        display: flex;
        h3 {
          line-height: 1.1;
          margin: 0;
        }
      }

      &:hover {
        opacity: 0.5;
      }
    }

    @media screen and (max-width: $bottom-break) and (min-width: $md-end) {
      h3 {
        font-size: 1.7vw;
      }

      img {
        padding-top: 4px;
        height: 1.7vw;
      }
    }

    @media screen and (max-width: $md) {
      img {
        height: 3.4rem;
      }

      h3,
      a span h3 {
        font-size: 2.6rem;
      }
    }
  }

  @media screen and (min-width: $md-end) {
    .leftSide {
      position: fixed;
      top: $spacer2;
      width: 20%;
      z-index: 10;
      height: 100%;

      #logoMenu {
        #communityLogo {
          width: 100%;
        }

        #hamburgerMenu {
          display: none;
        }
      }
    }

    #menuContents {
      display: block;
    }
  }

  @media screen and (max-width: $md) {
    .leftSide {
      width: 100%;
      #menuContents {
        z-index: 99;
        height: 100vh;
        display: none;
        margin-top: $spacer3;
        background: $black;
        width: calc(100% - 7rem);
        left: calc(3.6rem - 1px);
        padding-right: $spacer2;
      }

      p {
        font-size: 12px;
        line-height: 20px;
      }

      #filter {
        margin-bottom: $spacer2;
      }

      #logoMenu {
        padding-top: $spacer2;
        padding-bottom: $spacer2-3;
        width: 100%;
        height: $spacer2;
        background-color: $black;

        margin-top: 0 !important;
        display: flex;
        position: fixed;
        top: 0;
        z-index: 99;

        #communityLogo {
          width: 50%;
          position: fixed;
          left: $spacer2;
        }

        #hamburgerMenu {
          padding-top: 1%;
          position: fixed;
          right: $spacer2;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .mainSite {
        bottom: 0;
      }
    }

    #memberMap {
      margin-top: $spacer2 + $spacer2-3;
    }
  }

  h3,
  h4 {
    margin-top: $spacer2;
  }

  /*
* FILTER COMPONENT
*/

  #filter {
    h5 {
      float: right;
      cursor: pointer;
      padding-top: 4px;
      margin-top: $spacer2;
    }

    h5:hover {
      text-decoration: underline;
    }

    h3 {
      float: left;
    }

    br {
      clear: both;
    }

    @media only screen and (max-width: $md) {
      h3 {
        font-size: 2.6rem;
      }

      h5 {
        font-size: 2.3rem;
      }
    }
  }

  label {
    margin-top: $spacer1;
    display: flex;

    p {
      margin-left: $spacer1;
      line-height: 18px;
      vertical-align: middle;
    }
  }
}

select,
option {
  height: 35px;
  border: 1px solid $white;
  background: $black;
  margin-top: $spacer1;
}

/*
* MEMBER TILES COMPONENT
*/

.memberContainer {
  > img {
    width: 100%;
    height: auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  > img:hover {
    filter: none;
  }

  > p {
    margin: $base 0;
    overflow: hidden;
  }
  .info {
    display: flex;
    margin-top: $base;
    p {
      margin-left: $spacer1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  margin-bottom: $spacer2;
}

/*
* CONFIRM BOX COMPONENT
*/

.confirmBox {
  position: fixed;

  border: 2px solid $white;
  background-color: $black;
  z-index: 99999;

  #disagreeBtn {
    border: none;
  }

  button {
    margin-bottom: $spacer2;
  }
}

@media screen and (min-width: $md-end) {
  .confirmBox {
    top: 31%;
    left: 32%;
    width: 40%;
  }
}

@media screen and (max-width: $md) {
  .confirmBox {
    top: 30%;
    left: 10%;
    width: 80%;
  }
}

/*
* ABOUT COMPONENT
*/
#noResults {
  display: flex;
  width: 100%;
  align-items: center;
  height: calc(100vh - 7.2rem);
  justify-content: center;
  display: none;

  @media only screen and (max-width: $md) {
    height: auto;
    margin-top: $spacer4;
  }
}

.aboutPage {
  box-sizing: border-box;
  padding: $spacer2;
  border: 2px solid $white;
  margin-top: -$spacer2;
  position: fixed;
  right: 0;
  z-index: 1000;
  background-color: $black;
  width: 50%;
  height: 100vh;
  height: calc(var(--aboutHeight, 1vh) * 100);
  overflow: scroll;
  transform: translateX(100%);
  transition: 0.3s ease-out;

  @media screen and (max-width: $xl) and (min-width: $lg) {
    width: 60%;
  }

  @media screen and (max-width: $lg) and (min-width: $md) {
    width: 70%;
  }

  #about-dcom {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacer2-3;

    #closeButton {
      height: 2rem;
      width: 2rem;
      height: inherit;
    }

    #closeButton:hover {
      cursor: pointer;
    }

    h1 {
      line-height: 2.8rem;
    }
  }

  #aboutImg {
    width: 100%;
    -webkit-filter: grayscale(100%); /* safari6.0 - 9.0  */
    filter: grayscale(100%);
    height: 35vh;
    object-fit: cover;
  }

  #about-body-text {
    h5 {
      margin-top: $spacer2;
    }

    a {
      vertical-align: inherit;
      text-decoration: underline;
    }

    a:hover {
      text-decoration: none;
    }
  }
}

@media screen and (max-width: $md) {
  .aboutPage {
    width: 90%;
  }
}

#panel-darken {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateX(100%);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  > img {
    width: 100%;
    height: auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  > img:hover {
    filter: none;
  }
}
