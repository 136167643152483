// Breakpoints
$sm: 576px;
$md: 767px;
$md-end: 768px;
$lg: 992px;
$xl: 1400px;
$bottom-break: 1070px;

// Spacers
$base: 0.9rem;
$spacer1: $base * 2;
$spacer2: $spacer1 * 2;
$spacer2-3: $spacer2 * 1.3;
$spacer3: $spacer2 * 2;
$spacer4: $spacer3 * 2;

//Colors
$white: #ffffff;
$black: #000000;